<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 霍兰德测评 -->
        <div class="testbox">
            <div class="testcon">
                <div class="testleft">
                    <div class="t1">性格与选科选专业</div>
                    <img src="../../../assets/evaluation/evaluation_holland.jpg" class="img" alt="" />
                    <div class="t2">霍兰德职业测试</div>
                </div>
                <div class="testright">
                    <span>本测试依据霍兰德SDS职业兴趣测试模型，按照2015版《中华人民共和国职业分类大典》的职业分类，结合新高考条件和我国高中生现实情况研究而成，仅供考生和家长参考。</span><br/><br/>
                    <span>霍兰德职业适应性测验(The Self-Directed Search，简称SDS)由美国著名职业指导专家J.霍兰德（Holland）编制。在几十年间经过一百多次大规模的实验研究，形成了人格类型与职业类型的学说和测验。 霍兰德在其一系列关于人格与职业关系的假设的基础上，提出了六种基本的职业类型，即：实际型、研究型（调研型）、艺术型、社会型、企业型（事业型）、传统型（常规型）。该测验能帮助被试者发现和确定自己的职业兴趣和能力专长, 从而科学地做出求职择业。</span><br/><br/>
                    <span>霍兰德职业兴趣SDS测试一般是适合于高中生，通过此测试可以让高中生确定自己的兴趣爱好，给大学的专业选择提供参考。</span><br/><br/>
                    <span>人格和职业有着密切的关系，不同职业对从业者的人格特征的要求是有差距的，如果通过科学的测试，可以预知自己的人格特征，这有助于选择适合于个人发展的职业，进行职业倾向测试，可以帮助您作一次简单的人格自评，从而更加清楚自己的人格特征更适合从事哪方面的工作。</span><br/><br/>
                    <span>需要指出的是，志愿选择会受各种因素影响，例如录取分数、招生数量、就业前景、发展趋势等，测试结果不代表你只能选择测试分析的专业，而应根据个人自身情况综合考虑。</span>
                </div>
                <div class="clear"></div>
                <div class="btn" @click.prevent="startTest()">开始测评</div>
            </div>
            
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default{
    components:{
        PHeader,
        PFooter
    },
    methods:{
        startTest(){
            this.$store.dispatch('user/judgeLoginStatus').then(flag => {
                if (flag) {
                    this.$router.push('/holland-answer');
                }
                else {
                    this.$message.info('请先登录！');
                    this.$router.push('/login');
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.testbox{
    max-width: 1420px;
    margin: auto;
    .testcon{
        margin: 20px 20px 30px;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        .testleft{
            width: 400px;
            float: left;
            margin-left: 30px;
            text-align: center;
            .t1{
                color: #555;
                font-size: 30px;
            }
            .t2{
                color: rgba(var(--themecolor),0.75);
                font-size: 22px;
            }
            .img{
                display: block;
                margin: 30px auto  30px;
            }
        }
        .testright{
            width:calc(100% - 480px);
            float: right;
            margin-right: 30px;
            color: #666;
            font-size: 14px;
            line-height: 26px;
            margin-top: 3px;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            background-color: rgba(var(--themecolor),0.85);
            margin: 80px auto 0px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            border-radius: 5px;
        }
        .btn:hover{
            cursor: pointer;
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>